import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestApi from "../api/RestApi";

// Async thunk for Upline users
export const ListupLineUsers = createAsyncThunk(
  "user/upLineUsers",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/allDirectRefUser", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const userPayouts = createAsyncThunk(
  "user/payouts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/payouts", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for downline users
export const ListDownLineUsers = createAsyncThunk(
  "user/ListDownLineUsers",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/allDownLineUser", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const withdrawFund = createAsyncThunk(
  "user/withdrawFund",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/withdrawFunds",
        formData,
        config
      );
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listFund = createAsyncThunk(
  "user/listFund",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listFundsbyId", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchTransaction = createAsyncThunk(
  "user/fetchTransaction",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listTxnbyUserId", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchSessions = createAsyncThunk(
  "user/fetchSessions",
  async (_, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listLiveSessions", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchQuotes = createAsyncThunk(
  "user/fetchQuotes",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listTodayQuotes", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchallGifts = createAsyncThunk(
  "user/fetchallGifts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listGiftsbyUserId", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const vendorDetails = createAsyncThunk(
  "auth/vendorDetails",
  async (_, { getState, rejectWithValue }) => {
    try {
      // Make request to the backend
      const { data } = await RestApi.get("/app/listVendorStoreDetails");
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// user slice with initial state
const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    upline: null,
    payouts: null,
    downline: null,
    fundsList: null,
    userTxn: null,
    liveSession: null,
    gifts: null,
    quotes: null,
    error: null,
    success: null,
    listVendorStore: null,
  },
  reducers: {
    clearError: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(vendorDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(vendorDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.listVendorStore = payload;
        state.success = "success";
      })
      .addCase(vendorDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ListupLineUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(ListupLineUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.upline = payload;
      })
      .addCase(ListupLineUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userPayouts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(userPayouts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.payouts = payload;
      })
      .addCase(userPayouts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(ListDownLineUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(ListDownLineUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.downline = payload;
      })
      .addCase(ListDownLineUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(withdrawFund.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(withdrawFund.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(withdrawFund.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(listFund.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(listFund.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.fundsList = payload;
      })
      .addCase(listFund.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchTransaction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fetchTransaction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userTxn = payload;
      })
      .addCase(fetchTransaction.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchSessions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fetchSessions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.liveSession = payload;
      })
      .addCase(fetchSessions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchQuotes.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fetchQuotes.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.quotes = payload;
      })
      .addCase(fetchQuotes.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // fetch all gifts
      .addCase(fetchallGifts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fetchallGifts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.gifts = payload;
      })
      .addCase(fetchallGifts.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError } = userSlice.actions;
export default userSlice.reducer;
