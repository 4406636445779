// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import RestApi from '../api/RestApi';

// Async thunk for user login
export const userRegister = createAsyncThunk(
  'auth/userRegister',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post('auth/user/create', formData, config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for user login
export const userLogin = createAsyncThunk(
  'auth/user/login',
  async (formData, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post('/auth/user/login', formData, config);

      // Store user's token in local storage
      sessionStorage.setItem('userInfo', JSON.stringify(data?.userDetails));

      return data?.userDetails;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for user login
export const updateUserDetails = createAsyncThunk(
  'auth/updateUserDetails',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        '/app/updateUserProfile',
        formData,
        config,
      );

      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for user login
export const updateKycDetails = createAsyncThunk(
  'auth/updateKycDetails',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        '/app/userKycVerifications',
        formData,
        config,
      );

      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);
export const userDetailsById = createAsyncThunk(
  'auth/userDetailsById',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Make request to the backend
      const { data } = await RestApi.get('/app/userDetailsById', config);
      sessionStorage.setItem('userInfo', JSON.stringify(data?.userDetails));
      return data?.userDetails;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);
export const listUserKyc = createAsyncThunk(
  'auth/listUserKyc',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await RestApi.get('/app/listUserKyc', config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);

// to Update the password
export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async ({ memberId, newPassword }, { getState, rejectWithValue }) => {
    try {
      // Make request to the backend
      const { data } = await RestApi.patch(
        `/uth/user/updatePassword/${memberId}`,
        { newPassword: newPassword },
      );

      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  },
);

export const sponserDetails = createAsyncThunk(
  'auth/sponserDetails',
  async ({ memberId }, { rejectWithValue }) => {
    try {
      // Make request to the backend
      const { data } = await RestApi.get(`/app/userDetailsByref/${memberId}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Auth slice with initial state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    userInfo: sessionStorage.getItem('userInfo')
      ? JSON.parse(sessionStorage.getItem('userInfo'))
      : null,
    sponsers: null,
    kycDetails: null,
    list: null,
    error: null,
    success: null,
  },
  reducers: {
    clearError: state => {
      state.success = null;
      state.error = null;
    },
    logout: state => {
      sessionStorage.removeItem('userInfo'); // Deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.success = payload?.message;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userDetailsById.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(userDetailsById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
        state.success = payload?.message;
      })
      .addCase(userDetailsById.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(listUserKyc.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(listUserKyc.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.kycDetails = payload;
        state.success = payload?.message;
      })
      .addCase(listUserKyc.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(userRegister.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(userRegister.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(userRegister.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(updateUserDetails.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(updateUserDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(updateUserDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Sponser Details
      .addCase(sponserDetails.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(sponserDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sponsers = payload;
        state.success = payload?.message;
      })
      .addCase(sponserDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(updateKycDetails.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(updateKycDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(updateKycDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      // update password
      .addCase(updatePassword.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(updatePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError, logout } = authSlice.actions;
export default authSlice.reducer;
