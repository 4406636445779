import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import { AiOutlineYoutube } from 'react-icons/ai';
import { CiFacebook } from 'react-icons/ci';
import {
  Box,
  Container,
  Typography,
  Grid,
  Link,
  Divider,
  IconButton,
} from '@mui/material';
import { Mail } from '@mui/icons-material';

const Footer = () => {
  const cYear = new Date().getFullYear();

  const footerNavs = [
    {
      label: 'Company',
      items: [
        {
          to: '/terms_and_conditions',
          name: 'Terms & Conditions',
        },
        {
          to: '/privacy_and_policy',
          name: 'Privacy & Policy',
        },
      ],
    },
  ];

  return (
    <Box sx={{ backgroundColor: '#fdc87b', color: 'black', py: 5, px: 4 }}>
      <Container maxWidth="xl">
        <Grid container spacing={2} justifyContent="">
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                width: 70,
                height: 'auto',
                display: 'inline-flex',
                objectFit: 'contain',
                padding: 0,
                margin: 0,
              }}
              component="img"
              src="/logo.png"
              alt="logo"
            />

            <Typography variant="body2">
              SK Enterprises excels in direct selling with top-notch products,
              education, and online services.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {footerNavs.map((item, index) => (
              <Box key={index + 1}>
                <Typography variant="h5">{item.label}</Typography>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {item.items.map((el, index) => (
                    <li key={index + 1}>
                      <Link
                        component={RouterLink}
                        to={el.to}
                        sx={{
                          color: 'black',
                          fontSize: 15,
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                            color: 'black',
                          },
                        }}
                      >
                        {el.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                display: 'flex',
                justifyItems: 'start',
                alignItems: 'start',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography variant="h5">Contact Us</Typography>

              <Typography
                component="a"
                href="mailto:teamskgroup88@gmail.com"
                sx={{ cursor: 'pointer' }}
              >
                <Mail size={20} /> teamskgroup88@gmail.com
              </Typography>
              <Typography
                component="a"
                href="https://api.whatsapp.com/send/?phone=917892222108&text&type=phone_number&app_absent=0"
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  component="img"
                  src="/assets/icons/whatsapp.png"
                  alt="whatsapp"
                  sx={{ width: 24 }}
                />{' '}
                +91 7892222108
              </Typography>
            </Box>
            <Box sx={{ cursor: 'pointer' }}>
              <IconButton
                href="https://www.instagram.com"
                sx={{ color: 'black', mr: 1 }}
              >
                <FaInstagram size={20} />
              </IconButton>
              <IconButton
                href="https://www.facebook.com"
                sx={{ color: 'black', mr: 1 }}
              >
                <CiFacebook size={22} />
              </IconButton>
              <IconButton
                href="https://www.youtube.com"
                sx={{ color: 'black' }}
              >
                <AiOutlineYoutube size={23} />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6">Registered Office</Typography>
            <Typography sx={{ fontSize: 15, mt: 1 }}>
              No.11/1, Lakashmipur Main Road,
              <br />
              1st Cross Behind Sai Enclave,
              <br />
              Near Vaikunta Bar, Gangondanahalli
              <br />
              Bengaluru, Karnataka 562123
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4, borderColor: 'black' }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 1,
          }}
        >
          <Typography>&copy; {cYear} SkPay. All rights reserved.</Typography>

          <Box display="flex" alignItems="center">
            <Link
              href="https://api.whatsapp.com/send/?phone=917892222108&text&type=phone_number&app_absent=0"
              sx={{
                color: 'black',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                cursor: 'pointer',
              }}
            >
              <Box
                component="img"
                src="/assets/icons/whatsapp.png"
                alt="whatsapp"
                sx={{ width: 32 }}
              />
              +91 7892222108
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
