import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import planReducer from "./planSlice";
import categoryReducer from "./categorySlice";
import productReducer from "./productSlice";
import bannerReducer from "./bannerSlice";
import userReducer from "./userSlice";
import rechargeReducer from "./rechargeSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    plan: planReducer,
    category: categoryReducer,
    product: productReducer,
    banner: bannerReducer,
    user: userReducer,
    recharge: rechargeReducer,
  },
});
export default store;
