import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RestApi from '../api/RestApi';

// Async Thunk for listing all plans
export const listAllPlans = createAsyncThunk(
  'plans/listAll',
  async (_, { getState, rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await RestApi.get('/app/listplan', config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for fetching a plan by its ID
export const fetchPlanById = createAsyncThunk(
  'plans/fetchById',
  async (planId, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get(`/plans/${planId}`, config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for fetching user's plans
export const fetchUserPlans = createAsyncThunk(
  'plans/fetchUserPlans',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await RestApi.get('/app/userPlan', config);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for creating an order
export const createOrder = createAsyncThunk(
  'plans/createOrder',
  async (amount, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await RestApi.post(
        '/app/createOrder',
        { payableAmount: amount },
        config,
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  },
);

// Async Thunk for buying a plan
export const buyPlan = createAsyncThunk(
  'plans/buyPlan',
  async (plan, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await RestApi.post('/app/buyPlan', plan, config);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const planSlice = createSlice({
  name: 'plans',
  initialState: {
    planList: null,
    userPlan: null,
    selectedPlan: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: state => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      // List all plans
      .addCase(listAllPlans.pending, state => {
        state.loading = true;
      })
      .addCase(listAllPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.planList = action.payload;
      })
      .addCase(listAllPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // List users' plans
      .addCase(fetchUserPlans.pending, state => {
        state.loading = true;
      })
      .addCase(fetchUserPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.userPlan = action.payload;
      })
      .addCase(fetchUserPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Buy plan
      .addCase(buyPlan.pending, state => {
        state.loading = true;
      })
      .addCase(buyPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(buyPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch plan by ID
      .addCase(fetchPlanById.pending, state => {
        state.loading = true;
      })
      .addCase(fetchPlanById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedPlan = action.payload;
      })
      .addCase(fetchPlanById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = planSlice.actions;

export default planSlice.reducer;
