import { v4 as uuidv } from "uuid";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  ListItemIcon,
  useTheme,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Logout as LogoutIcon,
  Chat as ChatIcon,
} from "@mui/icons-material";

import { useResponsive } from "../../hooks/use-responsive";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import Logo from "../../components/logo";
import { HEADER } from "../dashboard/config-layout";

const userNavigation = [
  { name: "My Profile", to: "/profile", icon: <AccountCircleIcon /> },
  { name: "User Dashboard", to: "/app/dashboard", icon: <AccountCircleIcon /> },
  { name: "Contact Us", to: "/", icon: <ChatIcon /> },
];

const Header = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  const lgUp = useResponsive("up", "lg");
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoutHandler = () => {
    dispatch(logout());
    handleCloseUserMenu();
  };

  return (
    <AppBar
      component="nav"
      sx={{
        backgroundImage: "linear-gradient(to bottom, #3588f2, #09b4d6)",
        color: theme.palette.text.primary,
        boxShadow: "none",
        height: HEADER.H_DESKTOP,
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 8 },
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <Logo />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                width: 70,
                height: "auto",
                display: "inline-flex",
                objectFit: "contain",
                padding: 0,
                margin: 0,
              }}
              src="/logo2.png"
              alt="logo"
            />
            <Typography variant="caption">Success is Knowledge</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 3,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box onClick={handleOpenUserMenu} color="inherit">
            {userInfo !== null ? (
              <Avatar
                sx={{
                  color: "primary.main",
                  backgroundColor: "common.white",
                  width: 36,
                  height: 36,
                  border: (theme) =>
                    `solid 2px ${theme.palette.background.default}`,
                }}
              >
                {userInfo.name.charAt(0).toUpperCase()}
              </Avatar>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                size="medium"
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.common.black,
                  boxShadow: 1,
                  textTransform: "uppercase",
                  borderRadius: 1,
                  paddingX: 3,
                  paddingY: 1,
                  "&:hover": {
                    color: theme.palette.common.white,
                    backgroundColor: "#dc81fc",
                  },
                }}
              >
                Log in
              </Button>
            )}
          </Box>
          <Menu
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              elevation: 1,
              sx: {
                overflow: "visible",
                my: 1,
                paddingY: 2,
                paddingX: 1,
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {userInfo !== null ? (
              <>
                {userNavigation?.map((item) => (
                  <MenuItem
                    key={uuidv()}
                    component={Link}
                    to={item.to}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      textWrap: "nowrap",
                      color: "primary.main",
                      borderColor: "#000",
                      borderBottom: 1,
                    }}
                  >
                    <ListItemIcon sx={{ color: "primary.main" }}>
                      {item.icon}
                    </ListItemIcon>
                    {item.name}
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={logoutHandler}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    color: "primary.main",
                    borderColor: "black",
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: "primary.main" }} />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem onClick={handleCloseNavMenu} sx={{ borderBottom: 1 }}>
                  <Box
                    component="a"
                    href="/register"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography variant="body2">New User?</Typography>
                    <Typography variant="h6" sx={{ color: "primary.main" }}>
                      Sign Up
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    color: "primary.main",
                    borderBottom: 1,
                    borderColor: "black",
                  }}
                >
                  <Link to="/login">Member Logins</Link>
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton
            sx={{ padding: 0 }}
            size="large"
            aria-label="menu-appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
          >
            <MenuIcon
              fontSize="large"
              sx={{ color: theme.palette.common.white }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            PaperProps={{
              sx: {
                minWidth: "100",
                paddingY: 2,
                paddingX: 1,
                my: 1,
              },
            }}
          >
            {!userInfo ? (
              <>
                <MenuItem onClick={handleCloseNavMenu} sx={{ borderBottom: 1 }}>
                  <Box
                    component="a"
                    href="/register"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography variant="body2">New User?</Typography>
                    <Typography variant="h6" sx={{ color: "primary.main" }}>
                      Sign Up
                    </Typography>
                  </Box>
                </MenuItem>
                <MenuItem
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "center",
                    color: "primary.main",
                    borderBottom: 1,
                    borderColor: "black",
                  }}
                >
                  <Link to="/login">
                    <Typography variant="body1">Member Login</Typography>
                  </Link>
                </MenuItem>
              </>
            ) : (
              <>
                {userNavigation?.map((item) => (
                  <MenuItem
                    key={uuidv()}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={item.to}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      textWrap: "nowrap",
                      color: "primary.main",
                      borderColor: "black",
                      borderBottom: 1,
                    }}
                  >
                    <ListItemIcon sx={{ color: "primary.main" }}>
                      {item.icon}
                    </ListItemIcon>
                    {item.name}
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={logoutHandler}
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    color: "primary.main",
                  }}
                >
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: "primary.main" }} />
                  </ListItemIcon>
                  Sign out
                </MenuItem>
              </>
            )}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
